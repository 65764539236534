<template>
  <div id="main">
    <!-- <form
      name="wholesaleForm"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <div class="row">
        <input type="hidden" name="form-name" value="ask-question" />
        <div class="col-xs-12">
          <input type="text" placeholder="Name" name="name" />
        </div>
        <div class="col-xs-12">
          <br>
          <textarea placeholder="Message" name="message" />
        </div>
        <div class="col-xs-12">
          <br>
          <button type="submit">Send</button>
        </div>
      </div>
    </form> -->
    <img
      src="/img/bareiz_logo2.png"
      alt="Bareiz"
      class="logo"
    >
    <br><br><br><br>
    <h1>Wholesale</h1>
    <br>
    <p class="intro">Already have a wholesale account?</p>
    <br>
    <a href="https://wholesale.bareiz.ch/" class="cta">Login</a>
    <br><br>
    <br><br>
    <p class="intro">
      Interested in becoming a stockist?
      <br>
      Please fill out the information below and we will
      <br>
      get back to you as soon as possible.
    </p>
    <iframe ref="frame" src="https://docs.google.com/forms/d/e/1FAIpQLSex30AP7w3JX1CvFxIJoN9zqU8ky6DUhyRydwpGK8ecVyB2Dg/viewform?embedded=true" width="640" :height="iframeHeight" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data: () => ({
    iframeHeight: 2800,
  }),
  mounted() {
    this.height = this.$refs.frame.contentWindow.document.body.scrollHeight;
  },
};
</script>

<style lang="scss" scoped>
#main {
  max-width: 650px;
  margin: 4em auto;
  text-align: center;
  color: #473023;
}

.logo {
  width: 250px;
  margin: 0 auto;
}

.cta {
  border: 1px solid #473023;
  color: #473023;
  text-decoration: none;
  padding: .5em 3em;
}

.intro {
  font-size: 1.4em;
  color: #473023;
}
</style>
